<template>
  <div>
    <Modal
      :show="showDeleteBaseModal"
      :title="text.sp_warning_title"
      @cancel="
        () => {
          this.showDeleteBaseModal = false;
        }
      "
      :hide-buttons="true"
    >
      <template slot="body">
        {{ text.sp_warning_text[language] }}
      </template>
    </Modal>
    <div class="tech-req__title">
      {{ text.menu_relative_position[language] }}
    </div>
    <Button
      @click="setShowFilters"
      :text="!showFilters ? text.sp_show_filter[language] : text.sp_hide_filter[language]"
      type="attention"
      style="margin-bottom: 20px"
      block
    />
    <div class="tech-req__block_wrapper" v-if="showFilters">
      <div class="tech-req__block_container">
        <div>
          <div v-if="showArrFilters.length > 0">
            <div class="tech-req__block_subtitle" style="margin-bottom: 20px">
              {{ text.sp_arr_acc[language] }}
            </div>
            <div>
              <div class="block block__between block__align-center" style="margin-bottom: 20px">
                <div
                  v-for="(item, index) in showArrFilters.filter((el, i) => i < 4)"
                  v-bind:key="`arr1_f_${index}`"
                >
                  <AccuracyType
                    :type="item"
                    @click="setActiveFilter($event)"
                    v-if="item !== 'empty'"
                    :active="filters[item]"
                  />
                  <div v-else style="width: 50px; min-width: 50px; height: 35px"></div>
                </div>
              </div>
            </div>
            <div v-if="showArrFilters.length > 5">
              <div class="block block__between block__align-center" style="margin-bottom: 20px">
                <div
                  v-for="(item, index) in showArrFilters.filter((el, i) => i >= 4)"
                  v-bind:key="`arr2_f_${index}`"
                >
                  <AccuracyType
                    :type="item"
                    @click="setActiveFilter($event)"
                    :active="filters[item]"
                    v-if="item !== 'empty'"
                  />
                  <div v-else style="width: 50px; min-width: 50px; height: 35px"></div>
                </div>
              </div>
            </div>
          </div>
          <Button
            @click="setActiveFilter('all')"
            block
            :type="filters.all ? 'attention' : 'default'"
            :text="
              filters.all ? text.sp_filter_hide_all[language] : text.sp_filter_show_all[language]
            "
          />
        </div>
      </div>
    </div>
    <div class="tech-req__block_wrapper" v-if="showEdit">
      <div class="tech-req__block_container">
        <div class="tech-req__block_title" style="margin-bottom: 20px">
          {{ text.sp_base[language] }}
        </div>
        <div style="margin-bottom: 20px">
          <span class="tech-req__block_subtitle"> {{ text.sp_base_surface[language] }}: </span>
          <span class="tech-req__block_value component__margin-left">
            {{ bases.filter((el) => el.type === "surface").length }}
          </span>
        </div>
        <div
          v-for="surface in bases.filter((el) => el.type === 'surface')"
          v-bind:key="`base_s_${surface.id}`"
        >
          <div class="block block__align-center" style="margin-bottom: 10px">
            <div
              class="tech-req__base_container"
              :style="{
                backgroundColor: `rgb(${surface.color.r * 255},${surface.color.g * 255},${
                  surface.color.b * 255
                })`,
              }"
            >
              {{ surface.name }}
            </div>
            <div
              @click="chooseBase('surface', surface.id, surface)"
              @mouseenter="setHoveredItem(`base_${surface.id}`)"
              @mouseleave="setHoveredItem(null)"
              :class="`component__margin-left tech-req__base_button ${setBaseStyle(
                surface.id,
                'surface',
                surface,
              )}`"
            >
              <div>{{ setBaseText(surface.id, "surface", surface) }}</div>
              <CloseIcon
                @click="deleteBase(surface.id)"
                v-if="hoveredItem === `base_${surface.id}` && showEdit"
                class="tech-req__base_button_cancel"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showEdit"
          :style="`${disableAddBase ? 'background-color: rgba(0,0,0, .1)' : ''}`"
          class="tech-req__block_add"
          @click="addBase('surface')"
        >
          <AddLogo />
        </div>
        <div style="margin-bottom: 20px">
          <span class="tech-req__block_subtitle"> {{ text.sp_base_axis[language] }}: </span>
          <span class="tech-req__block_value component__margin-left">
            {{ bases.filter((el) => el.type === "axis").length }}
          </span>
        </div>
        <div
          v-for="surface in bases.filter((el) => el.type === 'axis')"
          v-bind:key="`base_a_${surface.id}`"
        >
          <div class="block block__align-center" style="margin-bottom: 10px">
            <div
              class="tech-req__base_container"
              :style="{
                backgroundColor: `rgb(${surface.color.r * 255},${surface.color.g * 255},${
                  surface.color.b * 255
                })`,
              }"
            >
              {{ surface.name }}
            </div>
            <div
              @mouseenter="setHoveredItem(`base_${surface.id}`)"
              @mouseleave="setHoveredItem(null)"
              @click="chooseBase('axis', surface.id, surface)"
              :class="`component__margin-left tech-req__base_button ${setBaseStyle(
                surface.id,
                'axis',
                surface,
              )}`"
            >
              <div>{{ setBaseText(surface.id, "axis", surface) }}</div>
              <CloseIcon
                @click="deleteBase(surface.id)"
                v-if="hoveredItem === `base_${surface.id}` && showEdit"
                class="tech-req__base_button_cancel"
              />
            </div>
          </div>
        </div>
        <div
          :style="`${disableAddBase ? 'background-color: rgba(0,0,0, .1)' : ''}`"
          v-if="showEdit"
          class="tech-req__block_add"
          @click="addBase('axis')"
        >
          <AddLogo />
        </div>
        <Button
          v-if="addBaseAxisIndex !== null || addBaseSurfaceIndex !== null"
          type="primary"
          block
          :text="text.sp_cancel[language]"
          @click="cancelAddBase"
        />
      </div>
    </div>
    <div class="tech-req__block_wrapper" v-if="showEdit">
      <div class="tech-req__block_container">
        <div class="tech-req__block_title" style="margin-bottom: 20px">
          {{ text.sp_add[language] }}
        </div>
        <div>
          <div class="tech-req__block_subtitle" style="margin-bottom: 20px">
            {{ text.sp_arr_acc[language] }}
          </div>
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div
              v-for="(item, index) in typesArr.filter((el, i) => i < 4)"
              v-bind:key="`add_arr1_${index}`"
            >
              <AccuracyType @click="addTolerance(item)" :type="item" />
            </div>
          </div>
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div
              v-for="(item, index) in typesArr.filter((el, i) => i >= 4)"
              v-bind:key="`add_arr2_${index}`"
            >
              <AccuracyType @click="addTolerance(item)" :type="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in tolerances" v-bind:key="`tolerances_${item.id}`">
      <div class="tech-req__block_wrapper" v-if="filters[item.type]">
        <div
          class="tech-req__block_container"
          v-if="!item.countType || item.countType === 'default'"
        >
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div class="block__flex block__align-center">
              <AccuracyType :type="item.type" />
              <div style="margin-left: 10px; margin-right: 10px" class="tech-req__base_name">
                {{ text[`sp_${item.type}`][language] }}
              </div>
            </div>
            <Input
              v-if="item.edit"
              :max-width="60"
              :step="0.001"
              :min="0"
              :default-value="item.value ? item.value : '0'"
              type="number"
              @change="saveValue(index, $event)"
              :margin-bottom="0"
            />
            <div v-if="!item.edit" class="tech-req__block_show-value">
              {{ item.value ? item.value : "0" }}
            </div>
          </div>
          <div v-if="item.count < 2" style="margin-bottom: 20px">
            <div
              :class="`tech-req__base_status ${item.count === 0 && 'tech-req__base_status_active'}`"
            >
              {{ text.sp_tolerance_status_1[language] }}
            </div>
            <div
              :class="`tech-req__base_status ${item.count === 1 && 'tech-req__base_status_active'}`"
            >
              {{ text.sp_tolerance_status_2[language] }}
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div style="margin-bottom: 20px">
              <div class="block__flex block__align-center" style="margin-bottom: 10px">
                <div class="tech-req__block_subtitle">
                  {{ text.sp_choose_surface_title[language] }}:
                </div>
                <div class="component__margin-left tech-req__block_value">
                  {{ +!!item.surface1 + +!!item.surface2 }}
                </div>
              </div>
              <div v-if="item.surface1" style="margin-bottom: 10px">
                <div
                  @mouseenter="setHoveredItem('tol_surface1')"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurface(index, 'surface1')"
                  :class="`tech-req__base_button ${setItemStyle(1, 'surface', item)}`"
                >
                  <div>{{ setItemText(1, "surface", item) }}</div>
                  <CloseIcon
                    @click="deleteItem(index, 'surface1')"
                    v-if="hoveredItem === 'tol_surface1' && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
              <div v-if="item.surface2" style="margin-bottom: 10px">
                <div
                  @mouseenter="setHoveredItem('tol_surface2')"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurface(index, 'surface2')"
                  :class="`tech-req__base_button ${setItemStyle(2, 'surface', item)}`"
                >
                  <div>{{ setItemText(2, "surface", item) }}</div>
                  <CloseIcon
                    @click="deleteItem(index, 'surface2')"
                    v-if="hoveredItem === 'tol_surface2' && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
              <div
                v-if="item.count < 2 && item.edit"
                @click="addItem('surface', index)"
                :style="`${
                  item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
                } margin-top: 10px;`"
                class="tech-req__block_add"
              >
                <AddLogo />
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="block__flex block__align-center" style="margin-bottom: 10px">
                <div class="tech-req__block_subtitle">
                  {{ text.sp_choose_axis_title[language] }}:
                </div>
                <div class="component__margin-left tech-req__block_value">
                  {{ +!!item.axis1 + +!!item.axis2 }}
                </div>
              </div>
              <div v-if="item.axis1" style="margin-bottom: 10px">
                <div
                  @mouseenter="setHoveredItem('tol_axis1')"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurface(index, 'axis1')"
                  :class="`tech-req__base_button ${setItemStyle(1, 'axis', item)}`"
                >
                  <div>{{ setItemText(1, "axis", item) }}</div>
                  <CloseIcon
                    @click="deleteItem(index, 'axis1')"
                    v-if="hoveredItem === 'tol_axis1' && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
              <div v-if="item.axis2" style="margin-bottom: 10px">
                <div
                  @mouseenter="setHoveredItem('tol_axis2')"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurface(index, 'axis2')"
                  :class="`tech-req__base_button ${setItemStyle(2, 'axis', item)}`"
                >
                  <div>{{ setItemText(2, "axis", item) }}</div>
                  <CloseIcon
                    @click="deleteItem(index, 'axis2')"
                    v-if="hoveredItem === 'tol_axis2' && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
              <div
                v-if="item.count < 2 && item.edit"
                @click="addItem('axis', index)"
                :style="`${
                  item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
                } margin-top: 10px;`"
                class="tech-req__block_add"
              >
                <AddLogo />
              </div>
            </div>
            <div style="margin-bottom: 20px">
              <div class="block__flex block__align-center" style="margin-bottom: 10px">
                <div class="tech-req__block_subtitle">
                  {{ text.sp_choose_base_title[language] }}:
                </div>
                <div class="component__margin-left tech-req__block_value">
                  {{ +!!item.base }}
                </div>
              </div>
              <div v-if="item.base" style="margin-bottom: 10px">
                <Select
                  @change="setBaseForTolerance($event, index)"
                  :dropdown-props="{
                    label: 'name',
                    value: 'id',
                  }"
                  @clear="deleteItem(index, 'base')"
                  :clearable="true"
                  :values="bases"
                />
              </div>
              <div
                v-if="item.count < 2 && bases.length > 0 && item.edit"
                @click="addItem('base', index)"
                :style="`${
                  item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
                } margin-top: 10px;`"
                class="tech-req__block_add"
              >
                <AddLogo />
              </div>
              <div
                class="tech-req__block_add"
                style="
                  background-color: rgba(0, 0, 0, 0.1);
                  margin-top: 10px;
                  color: #282828;
                  font-size: 16px;
                  font-weight: 600;
                "
                v-if="item.count < 2 && bases.length === 0"
              >
                {{ text.sp_need_base[language] }}
              </div>
            </div>
          </div>
          <div class="block" v-if="showEdit">
            <Button
              @click="saveOrEdit(index)"
              block
              :type="item.edit ? 'primary' : 'attention'"
              :text="item.edit ? text.r_save[language] : text.sp_edit[language]"
            />
            <div
              class="tech-req__block_delete"
              @click="deleteTolerance(index)"
              style="margin-left: 10px"
            >
              <TrashIcon />
            </div>
          </div>
        </div>
        <div
          class="tech-req__block_container"
          v-if="item.countType && item.countType === 'infinity'"
        >
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div class="block__flex block__align-center">
              <AccuracyType :type="item.type" />
              <div style="margin-left: 10px; margin-right: 10px" class="tech-req__base_name">
                {{ text[`sp_${item.type}`][language] }}
              </div>
            </div>
            <Input
              v-if="item.edit"
              :max-width="60"
              :step="0.001"
              :min="0"
              :default-value="item.value ? item.value : '0'"
              type="number"
              @change="saveValue(index, $event)"
              :margin-bottom="0"
            />
            <div v-if="!item.edit" class="tech-req__block_show-value">
              {{ item.value ? item.value : "0" }}
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div style="margin-bottom: 20px">
              <div class="block__flex block__align-center" style="margin-bottom: 10px">
                <div class="tech-req__block_subtitle">
                  {{ text.sp_choose_surface_title[language] }}:
                </div>
                <div class="component__margin-left tech-req__block_value">
                  {{ item.surfaces.length }}
                </div>
              </div>
              <div v-if="item.surfaces && item.surfaces.length > 0">
                <div v-for="(surface, i) in item.surfaces" v-bind:key="`surf_${surface}`">
                  <div
                    style="margin-bottom: 15px"
                    @mouseenter="setHoveredItem(`tol_surface_${surface}`)"
                    @mouseleave="setHoveredItem(null)"
                    @click="showSurfaceInf(index, i, 'surface')"
                    :class="`tech-req__base_button ${setItemStyle(surface, 'surface', item)}`"
                  >
                    <div>{{ setItemTextInf(item, i, "surface") }}</div>
                    <CloseIcon
                      @click="deleteItemInf(index, i, 'surface')"
                      v-if="hoveredItem === `tol_surface_${surface}` && item.edit"
                      class="tech-req__base_button_cancel"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="
                  item.edit &&
                  (item.surfaces.length === 0 ||
                    item.surfaces[item.surfaces.length - 1] !== 'choose')
                "
                @click="addItemInf('surface', index)"
                :style="`${
                  item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
                } margin-top: 10px;`"
                class="tech-req__block_add"
              >
                <AddLogo />
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="block__flex block__align-center" style="margin-bottom: 10px">
              <div class="tech-req__block_subtitle">{{ text.sp_choose_axis_title[language] }}:</div>
              <div class="component__margin-left tech-req__block_value">
                {{ item.axes.length }}
              </div>
            </div>
            <div v-if="item.axes && item.axes.length > 0">
              <div v-for="(axis, i) in item.axes" v-bind:key="`axis_${axis}`">
                <div
                  style="margin-bottom: 15px"
                  @mouseenter="setHoveredItem(`tol_axis_${axis}`)"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurfaceInf(index, i, 'axis')"
                  :class="`tech-req__base_button ${setItemStyle(axis, 'axis', item)}`"
                >
                  <div>{{ setItemTextInf(item, i, "axis") }}</div>
                  <CloseIcon
                    @click="deleteItemInf(index, i, 'axis')"
                    v-if="hoveredItem === `tol_axis_${axis}` && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="
                item.edit &&
                (item.axes.length === 0 || item.axes[item.axes.length - 1] !== 'choose')
              "
              @click="addItemInf('axis', index)"
              :style="`${
                item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
              } margin-top: 10px;`"
              class="tech-req__block_add"
            >
              <AddLogo />
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div class="block__flex block__align-center" style="margin-bottom: 10px">
              <div class="tech-req__block_subtitle">{{ text.sp_choose_base_title[language] }}:</div>
              <div class="component__margin-left tech-req__block_value">
                {{ +!!item.base }}
              </div>
            </div>
            <div v-if="item.base" style="margin-bottom: 10px">
              <Select
                @change="setBaseForTolerance($event, index)"
                :dropdown-props="{
                  label: 'name',
                  value: 'id',
                }"
                @clear="deleteItem(index, 'base')"
                :clearable="true"
                :values="bases"
              />
            </div>
            <div
              v-if="bases.length > 0 && item.edit"
              @click="addItem('base', index)"
              :style="`${
                item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
              } margin-top: 10px;`"
              class="tech-req__block_add"
            >
              <AddLogo />
            </div>
            <div
              class="tech-req__block_add"
              style="
                background-color: rgba(0, 0, 0, 0.1);
                margin-top: 10px;
                color: #282828;
                font-size: 16px;
                font-weight: 600;
              "
              v-if="bases.length === 0"
            >
              {{ text.sp_need_base[language] }}
            </div>
          </div>
          <div class="block" v-if="showEdit">
            <Button
              @click="saveOrEdit(index)"
              block
              :type="item.edit ? 'primary' : 'attention'"
              :text="item.edit ? text.r_save[language] : text.sp_edit[language]"
            />
            <div
              class="tech-req__block_delete"
              @click="deleteTolerance(index)"
              style="margin-left: 10px"
            >
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/tech.json";
import AddLogo from "@/assets/images/tech/add.svg";
import CloseIcon from "@/assets/images/tech/close.svg";
import TrashIcon from "@/assets/images/tech/trash.svg";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";
import Select from "@/components/select/Select.vue";
import Modal from "@/components/modal/Modal.vue";
import { query } from "@/utilities/axios";
import AccuracyType from "./AccuracyType.vue";

export default {
  name: "AccuracyOfShapeAndPosition",
  data() {
    return {
      text,
      showEdit: true,
      activeClicked: false,
      activeIndex: null,
      activeToleranceType: null,
      clickedType: null,
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      activeColor: { r: 0.674, g: 0.674, b: 0.145 },
      showArrFilters: [],
      paramsLang: "ru",
      addBaseSurfaceIndex: null,
      addBaseAxisIndex: null,
      disableAddBase: false,
      typesArr: [
        "parallelism",
        "symmetry",
        "inclination",
        "orthogonal",
        "position",
        "beat",
        "alignment",
        "intersection",
      ],
      filters: {
        parallelism: true,
        symmetry: true,
        inclination: true,
        orthogonal: true,
        position: true,
        beat: true,
        alignment: true,
        intersection: true,
        all: true,
      },
      tolerances: [],
      bases: [],
      showFilters: false,
      hoveredItem: null,
      showDeleteBaseModal: false,
    };
  },
  props: {
    partData: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    checkFilters() {
      const shapeFilters = [];
      const arrFilters = [];
      for (const item of this.typesArr) {
        const index = this.tolerances.findIndex((el) => el.type === item);
        if (index >= 0) {
          arrFilters.push(item);
        }
      }
      if (shapeFilters.length > 0 && shapeFilters.length < 5) {
        [...Array(5 - shapeFilters.length).keys()].forEach(() => {
          shapeFilters.push("empty");
        });
      }
      if (arrFilters.length > 0 && arrFilters.length < 4) {
        [...Array(4 - arrFilters.length).keys()].forEach(() => {
          arrFilters.push("empty");
        });
      } else if (arrFilters.length > 0 && arrFilters.length < 8) {
        [...Array(8 - arrFilters.length).keys()].forEach(() => {
          arrFilters.push("empty");
        });
      }
      this.showArrFilters = arrFilters;
    },
    setActiveFilter(type) {
      if (type !== "all") {
        this.filters[type] = !this.filters[type];
        let allResult = true;
        for (const item of Object.keys(this.filters).filter((el) => el !== "all")) {
          if (!this.filters[item]) {
            allResult = false;
            break;
          }
        }
        this.filters.all = allResult;
      } else {
        const value = !this.filters.all;
        this.filters.all = value;
        for (const item of Object.keys(this.filters)) {
          this.filters[item] = value;
        }
      }
    },
    addBase(type) {
      if (!this.disableAddBase) {
        this.disableAddBase = true;
        if (this.bases.length === 0) {
          this.paramsLang = this.language;
        }
        let name = null;
        const baseLength = this.bases.length;
        const enLength = this.$store.state.techReq.alphabetEn.length;
        const enAlphabet = this.$store.state.techReq.alphabetEn;
        const ruLength = this.$store.state.techReq.alphabetRu.length;
        const ruAlphabet = this.$store.state.techReq.alphabetRu;
        if (this.paramsLang === "ru") {
          if (baseLength < ruLength) {
            name = ruAlphabet[baseLength];
          } else {
            name =
              ruAlphabet[baseLength - ruLength * +Math.floor(baseLength / ruLength)] +
              String(Math.floor(baseLength / ruLength));
          }
        } else if (this.paramsLang === "en") {
          if (baseLength < enLength) {
            name = enAlphabet[baseLength];
          } else {
            name =
              enAlphabet[baseLength - enLength * +Math.floor(baseLength / enLength)] +
              String(Math.floor(baseLength / enLength));
          }
        }
        this.bases.push({
          id: this.bases.length + 1,
          name: name,
          type: type,
          surface: null,
          color: this.setColor(Math.random(), 0.8, 0.56),
        });
        this.chooseBase(type, this.bases.length, this.bases[this.bases.length - 1]);
        this.$store.commit("modelV2/setShowModelFrame", true);
      }
    },
    setColor(percent, zero = 0.5, one = 0.89) {
      const change = one - zero;
      const summ = one + zero;
      const color = {
        r: 0,
        g: 0,
        b: 0,
      };
      if (percent >= 0 && percent <= 0.25) {
        color.r = zero;
        color.g = +(zero + (percent / 0.25) * change).toFixed(3);
        color.b = one;
      } else if (percent > 0.25 && percent <= 0.5) {
        color.r = zero;
        color.g = one;
        color.b = +(summ - (zero + (percent / 0.5) * change)).toFixed(3);
      } else if (percent > 0.5 && percent <= 0.75) {
        color.r = +(zero + (percent / 0.75) * change).toFixed(3);
        color.g = one;
        color.b = zero;
      } else {
        color.r = one;
        color.g = +(summ - (zero + percent * change)).toFixed(3);
        color.b = zero;
      }
      return color;
    },
    cancelAddBase() {
      this.addBaseAxisIndex = null;
      this.addBaseSurfaceIndex = null;
      this.activeClicked = false;
      this.clickedType = null;
      this.$store.commit("modelV2/setShowModelFrame", false);
    },
    setBaseText(index, type, surface) {
      let result = "";
      if (type === "axis") {
        if (index === this.addBaseAxisIndex) {
          result = this.text.sp_choose_base_axis[this.language];
        } else if (surface.surface && surface.surface !== "choose") {
          result = this.text.sp_show_base_axis[this.language];
        } else {
          result = this.text.sp_create_base_axis[this.language];
        }
      } else if (type === "surface") {
        if (index === this.addBaseSurfaceIndex) {
          result = this.text.sp_choose_base_surface[this.language];
        } else if (surface.surface && surface.surface !== "choose") {
          result = this.text.sp_show_base_surface[this.language];
        } else {
          result = this.text.sp_create_base_surface[this.language];
        }
      }
      return result;
    },
    setItemText(index, type, surface) {
      let result = "";
      if (type === "axis") {
        if (index === 1 && surface.axis1 === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else if (index === 2 && surface.axis2 === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else {
          result = this.text.sp_show_base_surface[this.language];
        }
      } else if (type === "surface") {
        if (index === 1 && surface.surface1 === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else if (index === 2 && surface.surface2 === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else {
          result = this.text.sp_show_base_surface[this.language];
        }
      }
      return result;
    },
    setItemTextInf(item, index, type) {
      let result = "";
      if (type === "axis") {
        if (item.axes && item.axes[index] && item.axes[index] === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else if (item.axes && item.axes[index] && item.axes[index] !== "choose") {
          result = this.text.sp_show_base_surface[this.language];
        }
      } else if (type === "surface") {
        if (item.surfaces && item.surfaces[index] && item.surfaces[index] === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else if (item.surfaces && item.surfaces[index] && item.surfaces[index] !== "choose") {
          result = this.text.sp_show_base_surface[this.language];
        }
      }
      return result;
    },
    setItemStyle(index, type, surface) {
      let result = "";
      if (type === "axis") {
        if (index === 1 && surface.axis1 === "choose") {
          result = "tech-req__base_button_in-process";
        } else if (index === 2 && surface.axis2 === "choose") {
          result = "tech-req__base_button_in-process";
        } else {
          result = "tech-req__base_button_active";
        }
      } else if (type === "surface") {
        if (index === 1 && surface.surface1 === "choose") {
          result = "tech-req__base_button_in-process";
        } else if (index === 2 && surface.surface2 === "choose") {
          result = "tech-req__base_button_in-process";
        } else {
          result = "tech-req__base_button_active";
        }
      }
      return result;
    },
    setBaseStyle(index, type, surface) {
      let result = "";
      if (type === "axis") {
        if (index === this.addBaseAxisIndex) {
          result = "tech-req__base_button_in-process";
        } else if (surface.surface && surface.surface !== "choose") {
          result = "tech-req__base_button_active";
        } else {
          result = "tech-req__base_button_not-active";
        }
      } else if (type === "surface") {
        if (index === this.addBaseSurfaceIndex) {
          result = "tech-req__base_button_in-process";
        } else if (surface.surface && surface.surface !== "choose") {
          result = "tech-req__base_button_active";
        } else {
          result = "tech-req__base_button_not-active";
        }
      }
      return result;
    },
    chooseBase(type, index, surface) {
      if (!surface.surface) {
        this.activeClicked = true;
        this.clickedType = "base";
        surface.surface = "choose";
        if (type === "axis") {
          this.addBaseAxisIndex = index;
        } else if (type === "surface") {
          this.addBaseSurfaceIndex = index;
        }
      } else if (surface.surface === "choose") {
        surface.surface = null;
        this.$store.commit("modelV2/setShowModelFrame", true);
        this.chooseBase(type, index, surface);
      } else {
        const result = [];
        result.push({
          surfaces: [surface.surface],
          color: surface.color,
        });
        this.$store.state.modelV2.decorateSurfaces(result);
      }
    },
    surfaceClicked(name) {
      if (this.activeClicked) {
        if (this.clickedType === "base") {
          const result = [];
          if (this.addBaseSurfaceIndex !== null) {
            const index = this.bases.findIndex((el) => el.id === this.addBaseSurfaceIndex);
            const bases = this.bases.slice();
            bases[index].surface = name;
            this.bases = bases;
            for (const base of bases) {
              if (base.surface) {
                result.push({
                  surfaces: [base.surface],
                  color: base.color,
                });
              }
            }
          } else if (this.addBaseAxisIndex !== null) {
            const index = this.bases.findIndex((el) => el.id === this.addBaseAxisIndex);
            const bases = this.bases.slice();
            bases[index].surface = name;
            this.bases = bases;
            for (const base of bases) {
              if (base.surface) {
                result.push({
                  surfaces: [base.surface],
                  color: base.color,
                });
              }
            }
          }
          this.$store.state.modelV2.decorateSurfaces(result);
          this.disableAddBase = false;
          this.cancelAddBase();
        } else if (this.clickedType === "tolerance") {
          const tolerances = this.tolerances.slice();
          const tolerance = tolerances[this.activeIndex];
          const result = [];
          if (this.activeToleranceType === "surface") {
            if (tolerance.countType === "default") {
              if (tolerance.surface1 === "choose") {
                tolerance.surface1 = name;
                result.push({
                  surfaces: [name],
                  color: this.clickColor,
                });
              } else if (tolerance.surface1 && tolerance.surface2 === "choose") {
                tolerance.surface2 = name;
                result.push({
                  surfaces: [tolerance.surface1, name],
                  color: this.clickColor,
                });
              }
            } else if (tolerance.countType === "infinity") {
              const chooseIndex = tolerance.surfaces.findIndex((el) => el === "choose");
              const existIndex = tolerance.surfaces.findIndex((el) => el === name);
              if (chooseIndex >= 0) {
                tolerance.surfaces[chooseIndex] = name;
              }
              if (existIndex >= 0) {
                tolerance.surfaces.splice(existIndex, 1);
              }
              result.push({
                surfaces: tolerance.surfaces,
                color: this.clickColor,
              });
            }
          } else if (this.activeToleranceType === "axis") {
            if (tolerance.countType === "default") {
              if (tolerance.axis1 === "choose") {
                tolerance.axis1 = name;
                result.push({
                  surfaces: [name],
                  color: this.clickColor,
                });
              } else if (tolerance.axis1 && tolerance.axis2 === "choose") {
                tolerance.axis2 = name;
                result.push({
                  surfaces: [tolerance.axis1, name],
                  color: this.clickColor,
                });
              }
            } else if (tolerance.countType === "infinity") {
              const chooseIndex = tolerance.axes.findIndex((el) => el === "choose");
              const existIndex = tolerance.axes.findIndex((el) => el === name);
              if (chooseIndex >= 0) {
                tolerance.axes[chooseIndex] = name;
              }
              if (existIndex >= 0) {
                tolerance.axes.splice(existIndex, 1);
              }
              result.push({
                surfaces: tolerance.axes,
                color: this.clickColor,
              });
            }
          }
          if (tolerance.base && tolerance.base !== "choose") {
            result.push({
              surfaces: [tolerance.base.surface],
              color: tolerance.base.color,
            });
          }
          this.$store.state.modelV2.decorateSurfaces(result);
          this.clickedType = null;
          tolerance.status = null;
          this.activeIndex = null;
          this.activeToleranceType = null;
          this.activeClicked = false;
          this.tolerances = tolerances;
          this.$store.commit("modelV2/setShowModelFrame", false);
        }
      }
    },
    setShowFilters() {
      this.showFilters = !this.showFilters;
    },
    addTolerance(type) {
      for (const item of this.tolerances) {
        item.edit = false;
      }
      const infinityMaxCountTypes = ["position"];
      const index = infinityMaxCountTypes.findIndex((el) => el === type);
      let countType = "default";
      if (index >= 0) {
        countType = "infinity";
      }
      this.tolerances.unshift({
        id: this.tolerances.length,
        type: type,
        value: null,
        surface1: null,
        surface2: null,
        axis1: null,
        axis2: null,
        surfaces: [],
        axes: [],
        base: null,
        status: null,
        count: 0,
        edit: true,
        countType: countType,
      });
      this.$store.state.modelV2.clearSurfaces();
      this.checkFilters();
      this.saveData();
    },
    saveValue(index, value) {
      this.tolerances[index].value = value;
    },
    addItem(type, index) {
      const tolerance = { ...this.tolerances[index] };
      if (tolerance.status !== "disable") {
        if (type === "surface") {
          this.activeClicked = true;
          this.$store.commit("modelV2/setShowModelFrame", true);
          if (!tolerance.surface1) {
            tolerance.surface1 = "choose";
          } else if (tolerance.surface1 && !tolerance.surface2) {
            tolerance.surface2 = "choose";
          }
        } else if (type === "axis") {
          this.activeClicked = true;
          this.$store.commit("modelV2/setShowModelFrame", true);
          if (!tolerance.axis1) {
            tolerance.axis1 = "choose";
          } else if (tolerance.axis1 && !tolerance.axis2) {
            tolerance.axis2 = "choose";
          }
        } else if (type === "base") {
          tolerance.base = "choose";
        }
        const tolerances = this.tolerances.slice();
        tolerances[index] = tolerance;
        this.tolerances = tolerances;
      }
      tolerance.count += 1;
      tolerance.status = "choose";
      this.clickedType = "tolerance";
      this.activeIndex = index;
      this.activeToleranceType = type;
    },
    addItemInf(type, index) {
      const tolerance = { ...this.tolerances[index] };
      if (tolerance.status !== "disable") {
        if (type === "surface") {
          this.activeClicked = true;
          this.$store.commit("modelV2/setShowModelFrame", true);
          tolerance.surfaces.push("choose");
        } else if (type === "axis") {
          this.activeClicked = true;
          this.$store.commit("modelV2/setShowModelFrame", true);
          tolerance.axes.push("choose");
        } else if (type === "base") {
          tolerance.base = "choose";
        }
        const tolerances = this.tolerances.slice();
        tolerances[index] = tolerance;
        this.tolerances = tolerances;
      }
      tolerance.count += 1;
      tolerance.status = "choose";
      this.clickedType = "tolerance";
      this.activeIndex = index;
      this.activeToleranceType = type;
    },
    setBaseForTolerance(val, index) {
      const tolerance = { ...this.tolerances[index] };
      tolerance.base = this.bases[val.value - 1];
      tolerance.status = null;
      const tolerances = this.tolerances.slice();
      tolerances[index] = tolerance;
      this.tolerances = tolerances;
      this.colorizeItem(index);
    },
    setHoveredItem(val) {
      this.hoveredItem = val;
    },
    deleteItem(index, type) {
      const tolerances = this.tolerances.slice();
      tolerances[index][type] = null;
      tolerances[index].count -= 1;
      tolerances[index].status = null;
      this.tolerances = tolerances;
      this.colorizeItem(index);
    },
    deleteItemInf(index, i, type) {
      const tolerances = this.tolerances.slice();
      if (type === "surface") {
        tolerances[index].surfaces.splice(i, 1);
      } else if (type === "axis") {
        tolerances[index].axes.splice(i, 1);
      }
      this.tolerances = tolerances;
      this.colorizeItemInf(index);
    },
    saveOrEdit(index) {
      if (this.tolerances[index].edit) {
        const tolerances = this.tolerances.slice();
        tolerances[index].edit = false;
        this.tolerances = tolerances;
        this.$store.state.modelV2.clearSurfaces();
        this.saveData();
      } else {
        const tolerances = this.tolerances.slice();
        tolerances[index].edit = true;
        this.tolerances = tolerances;
        this.colorizeItem(index);
      }
    },
    getPositionAccuracy() {
      return new Promise((resolve) => {
        query(
          "post",
          "getTechDataByPartOrder",
          {
            relative_position_accuracy: true,
            uuid: this.$route.params.uuid,
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((result) => {
          this.tolerances = result.relative_position_accuracy.tolerances
            ? result.relative_position_accuracy.tolerances
            : [];
          this.bases = result.relative_position_accuracy.bases
            ? result.relative_position_accuracy.bases
            : [];
          this.checkFilters();
          resolve();
        });
      });
    },
    saveData() {
      const tolerances = this.tolerances;
      const bases = this.bases;
      const accuracyPosition = {
        tolerances,
        bases,
      };
      query(
        "post",
        "changeTechDataByPartOrder",
        {
          hash: this.partData.hash,
          time_stamp: this.partData.time_stamp,
          file_name: this.partData.file_name,
          uuid: this.$route.params.uuid,
          relative_position_accuracy: accuracyPosition,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        // this.getHardness();
      });
    },
    colorizeItemInf(index) {
      const result = [];
      const item = this.tolerances[index];
      const surfaces = [];
      for (const el of item.surfaces) {
        if (el !== "choose") {
          surfaces.push(el);
        }
      }
      for (const el of item.axes) {
        if (el !== "choose") {
          surfaces.push(el);
        }
      }
      if (item.base && item.base !== "choose") {
        result.push({
          surfaces: [item.base.surface],
          color: item.base.color,
        });
      }
      result.push({
        surfaces: surfaces,
        color: this.clickColor,
      });
      this.$store.state.modelV2.decorateSurfaces(result);
    },
    colorizeItem(index) {
      const result = [];
      const item = this.tolerances[index];
      const surfaces = [];
      if (item.surface1 && item.surface1 !== "choose") {
        surfaces.push(item.surface1);
      }
      if (item.surface2 && item.surface2 !== "choose") {
        surfaces.push(item.surface2);
      }
      if (item.axis1 && item.axis1 !== "choose") {
        surfaces.push(item.axis1);
      }
      if (item.axis2 && item.axis2 !== "choose") {
        surfaces.push(item.axis1);
      }
      if (item.base && item.base !== "choose") {
        result.push({
          surfaces: [item.base.surface],
          color: item.base.color,
        });
      }
      result.push({
        surfaces: surfaces,
        color: this.clickColor,
      });
      this.$store.state.modelV2.decorateSurfaces(result);
    },
    deleteTolerance(index) {
      const tolerances = this.tolerances.slice();
      tolerances.splice(index, 1);
      this.tolerances = tolerances;
      this.saveData();
      this.checkFilters();
    },
    showSurface(index, type) {
      const result = [];
      const item = this.tolerances[index];
      const surfaces = [];
      const activeSurfaces = [];
      if (item.surface1 && item.surface1 !== "choose") {
        if (type === "surface1") {
          activeSurfaces.push(item.surface1);
        } else {
          surfaces.push(item.surface1);
        }
      }
      if (item.surface2 && item.surface2 !== "choose") {
        if (type === "surface2") {
          activeSurfaces.push(item.surface2);
        } else {
          surfaces.push(item.surface2);
        }
      }
      if (item.axis1 && item.axis1 !== "choose") {
        if (type === "axis1") {
          activeSurfaces.push(item.axis1);
        } else {
          surfaces.push(item.axis1);
        }
      }
      if (item.axis2 && item.axis2 !== "choose") {
        if (type === "axis2") {
          activeSurfaces.push(item.axis2);
        } else {
          surfaces.push(item.axis2);
        }
      }
      result.push({
        surfaces: surfaces,
        color: this.clickColor,
      });
      result.push({
        surfaces: activeSurfaces,
        color: this.activeColor,
      });
      this.$store.state.modelV2.decorateSurfaces(result);
    },
    showSurfaceInf(index, surface, type) {
      const result = [];
      const item = this.tolerances[index];
      let surfaces = [];
      const activeSurfaces = [];
      if (type === "surface") {
        if (item.surfaces && item.surfaces[surface] && item.surfaces[surface] !== "choose") {
          activeSurfaces.push(item.surfaces[surface]);
        } else {
          surfaces = item.surfaces.filter((element, i) => i !== surface);
        }
      } else if (type === "axis") {
        if (item.axes && item.axes[surface] && item.axes[surface] !== "choose") {
          activeSurfaces.push(item.axes[surface]);
        } else {
          surfaces = item.axes.filter((element, i) => i !== surface);
        }
      }
      result.push({
        surfaces: surfaces,
        color: this.clickColor,
      });
      result.push({
        surfaces: activeSurfaces,
        color: this.activeColor,
      });
      this.$store.state.modelV2.decorateSurfaces(result);
    },
    deleteBase(id) {
      let success = true;
      for (const item of this.tolerances) {
        if (item.base && item.base.id && item.base.id === id) {
          success = false;
        }
      }
      if (success) {
        const bases = this.bases.slice();
        bases.splice(id - 1, 1);
        this.bases = bases;
        this.disableAddBase = false;
        this.$store.state.modelV2.clearSurfaces();
      } else {
        this.showDeleteBaseModal = true;
      }
    },
  },
  mounted() {
    this.showEdit = this.edit;
    this.getPositionAccuracy();
    this.paramsLang = this.language;
    this.$store.commit("modelV2/setSurfaceClick", this.surfaceClicked);
  },
  components: {
    AccuracyType,
    Button,
    AddLogo,
    Input,
    Select,
    CloseIcon,
    TrashIcon,
    Modal,
  },
};
</script>
